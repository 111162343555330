:root {
    --navbar-color: #05202e;
	--first-color: #30F6A8;
    --secondary-color: #0B84E0;
	--text-navbar-color: #FFF;
    --text-first-color: #FFF;
    --text-secondary-color: #FFF;
    --light-gray-color: #ddd;
    --gray-color: #c3c3c3;
    --dark-gray-color: #999;
    --black-color: #424242;
    --white-color: #FFFFFF;
}
/********** theme **********/
* {
    box-sizing: border-box;
    padding: 0;
}
/********** material-symbols-outlined **********/
.material-symbols-outlined {
	font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' -25, 'opsz' 48;
	font-size: inherit;
}
.material-symbols-outlined.filled {
	font-variation-settings: 'FILL' 1;
}
.material-symbols-outlined.bold {
	font-variation-settings: 'wght' 300;
}
/********** splashScreen *****************/
#splashScreen {
	position: absolute;
	background-color: var(--navbar-color);
	color: var(--text-navbar-color);
    width: 100%;
    height: 100%;
    z-index: 10000000000000000000000;
}
#splashScreen #logo {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: 0;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	text-align: center;
}
#splashScreen #logo img {
    width: 150px;
    height: 150px;
	border-radius: 50%;
}
/********** cookieAlertDiv *****************/
#cookieAlertDiv,
/********** javascriptAlertDiv *****************/
#javascriptAlertDiv {
	display: none; 
	z-index: 100000000000;
	padding: 10px;
	background-color: red;
	color: #FFF;
	text-align:center;
}
/********** onsen *****************/
#myNavigator {
	height: 100%;
}
#myNavigator > div {
	background-color: transparent !important;
}
.page,
.page .page--material__content {
	font-family: 'Poppins';
}
.page__content .page__content {	
    margin: 0 auto;
	margin-top: -1px !important;
	bottom: 0;
}

.page-with-bottom-toolbar > .page__content {
	bottom: 0;
}
.page__background,
.page--material__background {
	margin-top: -1px;
}
#progressCircular {
	display: none;
	margin-top: 20px;
	padding-top: env(safe-area-inset-top);
	text-align: center;
	line-height: initial;
	height: auto;
}
#progressCircular .logo img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}
#progressCircular .title {
	font-size: 16px;
	font-weight: bold;
	color: var(--app-first-color);
}
#progressCircular.branded .title {
	color: var(--first-color);
}
#progressCircular .subtitle {
	font-size: 12px;
	color: #999;
}
/********** Toolbars **********/
.toolbar {
	background-color: var(--navbar-color);
	color: var(--text-navbar-color);
	background-image: none;
	box-shadow: none;
	height: auto;
	padding: 0;
	padding-top: env(safe-area-inset-top);
	overflow: initial;
}
.toolbar .left,
.toolbar .center,
.toolbar .right {
	font-family: 'Poppins';
	font-weight: normal;
	color: var(--text-navbar-color);
}
.toolbar .toolbar-button {	
	display: flex;
	align-items: center;
    cursor: pointer;
	padding: 0 10px;
    color: var(--text-navbar-color);
}
.toolbar .left,
.toolbar .right {
	z-index: 2;
	display: flex;
    align-items: center;
	min-width: auto;
	max-width: initial;
}
.toolbar .toolbar-button .icon {
	font-size: 30px;
}
.toolbar .toolbar-button .label {
    font-family: 'Poppins';
	margin-top: 0;
	margin-left: 5px;
	text-transform: uppercase;
	font-size: 14px;
    line-height: 14px;
}
.back-button,
.toolbar-back-button,
.back-button__icon {
    color: var(--text-navbar-color);
    fill: var(--text-navbar-color);
}
/********** FooterTabbar **********/
#footerTabbar {
	margin-top: env(safe-area-inset-top);
}
#footerTabbar .ons-tabbar__footer .tabbar__item .tabbar__button {
	height: auto;
}
#footerTabbar .ons-tabbar__footer .tabbar__item.active .tabbar__label,
#footerTabbar .ons-tabbar__footer .tabbar__item.active .tabbar__button {
    color: var(--first-color);
	font-weight: bold;
}
#footerTabbar > .ons-tabbar__footer {
	height: auto;
	background-color: #FFF;
	border-top: 1px solid #ccc;
}
#footerTabbar > .ons-tabbar__footer > .tabbar__border {
	display: none !important;
}
#footerTabbar .tabbar__button .icon {
	display: block;
	font-size: 24px;
	margin: 0 auto;
	margin-top: 7px;
	color: #9d9d9c;
}
#footerTabbar .ons-tabbar__footer .tabbar__item.active .tabbar__button .icon {
    color: var(--first-color);
    font-variation-settings: 'FILL' 1;
}
#footerTabbar .tabbar__label {
	font-size: 10px;
	line-height: 20px;
	padding-bottom: calc(env(safe-area-inset-bottom)/2);
}
ons-bottom-toolbar.bottomToolbar {
	width: 100%; /* Fallback */
	width: calc(100% - var(--scrollbar-width));
	margin: 0;
	height: auto;
	background-color: #ffffffc9;
	/*-webkit-backdrop-filter: blur(2px);
	backdrop-filter: blur(2px);
	box-shadow: 0 -2px 20px 5px rgba(0,0,0,0.1);
	background-color: #FFF;*/
	background-image: none;
	text-align: center;
	padding: 10px;
}
ons-bottom-toolbar.bottomToolbar .smartBtn {	
	width: fit-content;	
	min-width: 270px;
	max-width: 500px;
	height: 50px;
}





#content.container,
.bottomToolbar .container {
	padding: 0;
}
.bottomToolbar .container {
	text-align: center;
}

.toolbar.toolbar--material + .page__background + .page__content {
	top: 0;
	bottom: calc(env(safe-area-inset-bottom)/2);
	margin-top: 56px !important;
}
#topToolbar + .page__background + .page__content,
#backToolbar + .page__background + .page__content {
	bottom: 0;
	padding-top: env(safe-area-inset-top);
	padding-bottom: env(safe-area-inset-bottom);
}







#topToolbar ons-toolbar-button#favouriteBtn.active .icon {
	font-variation-settings: 'FILL' 1;
	color: red;
}
#topToolbar ons-toolbar-button .badge {
    position: relative;
    top: -15px;
    right: 5px;
    margin-top: env(safe-area-inset-top);
    font-size: 10px;
    line-height: 10px;
    padding: 2px 3px;
    border: 1px solid #FFF;
}
#topToolbar ons-toolbar-button.toolbar-button--material .badge {
	top: 8px;
}
/********** Toasts **********/
ons-toast {
	max-width: 500px;
    margin: 0 auto;
	z-index: 10002 !important;
}
ons-toast .toast {
	opacity: 1;
	max-width: 100%;
}
ons-fab.fab {
	background-color: var(--first-color);
	color: var(--text-first-color);
	cursor: pointer;
	box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 3px 5px 0 rgb(0 0 0 / 20%);    
}
ons-fab.fab:active {
	background-color: var(--first-color);
	color: var(--text-first-color);
	opacity: 0.5;
}
ons-fab.fab--bottom__right {
	bottom: 30px;
	right: 30px;
}
ons-fab.fab .fab__icon .icon {
    font-size: 40px;
    line-height: 56px;
}
@media screen and (max-width: 576px) {
	ons-fab.fab--bottom__right {
		bottom: 20px;
		right: 20px;
	}
}
/********** Pull-Hook **********/
ons-pull-hook {
	z-index: 1;
	display: none;
}
/********** Dialogs **********/
.dialog-mask {
	background-color: rgb(0 0 0 / 75%);
}
.dialog {
    width: 100%;
    height: 90%;
	z-index: 10001;
}
.dialog.dialog--material {
	box-shadow: none;
}
.dialog-container {
	display: flex;
	width: calc(100% - 1.5rem);
	min-height: calc(100% - 2rem);
	align-items: center;
    margin: 1rem auto;
    max-width: 35rem;
    position: relative;
	background-color: transparent;
}
.dialog-container > .page {
	/*position: relative;
    height: auto;*/
	border-radius: 15px;
}
/*
.dialog-container > .page > .page__content {
	position: relative;
}*/
.ons-ios-scroll-fix .page:not(.page--wrapper)[shown] > .page__content {
	overflow-y: scroll;
}
.dialog-container .toolbar.toolbar--material  {
	background-color: var(--navbar-color);
	color: var(--text-navbar-color);
    box-shadow: none;
	padding: 0 20px;
}
.dialog-container .left .toolbar-button {
	margin-left: -15px;
}
.dialog-container .right .toolbar-button {
	margin-right: -15px;
}
.dialog-container .toolbar-button svg {
    width: 26px;
    height: 26px;
    fill: #FFF;
}
.dialog-container .toolbar-button .ripple {
    width: 26px;
    height: 26px;
    margin-left: 10px;
}
.dialog-container ons-pull-hook {
    display: none;
    height: 56px !important;
    line-height: 56px !important;
}
/********** Tabbar **********/
.tabbar--top {
	background-color: #FFF;
	border: none;
	border-bottom: 1px solid var(--light-gray-color);
}
.tabbar--top__content .page__content {
	margin-top: 0 !important;
}
.tabbar--material {
	box-shadow: none;
}
.tabbar--material__border {
	height: 1.5px;
    background-color: var(--first-color);
}
.tabbar__button {
	cursor: pointer;
}
.tabbar__item .tabbar__label {
	color: #9d9d9c;
	white-space: normal;
	word-break: normal;
	font-weight: normal;
    font-size: 12px;
}
.tabbar__item.active .tabbar__label {
	color: var(--first-color);	
    font-weight: bold;
}
/********** intlTelInput **********/
.iti {
	z-index: 20002;
}
.iti .iti__selected-flag {
	height: auto;
    padding: 11.5px 6px;
	background-color: var(--light-gray-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
/********** testBadge **********/
#testBadge {
	position: absolute;
	z-index: 1000;
	width: 100%;
	text-align: center;
	top: env(safe-area-inset-top);
}
#testBadge span {
	width: auto;
	padding: 2px;
	font-size: 10px;
	font-weight: bold;
	background-color: red;
	color: #FFF;
}
/********** Daemonite Materials **********/
.progress {
	display: none;
	position: sticky;
    top: 0;
}
.progress .progress-bar {
	overflow: initial;
}
.nav-tabs .nav-link .icon {
	font-size: 24px;
    line-height: 14px;
}
.nav-tabs .nav-link.active {
	color: var(--first-color);
	font-weight: normal;
}
.nav-tabs .nav-link.active:before,
.nav-tabs-material .nav-tabs-indicator {
	background-color: var(--first-color);
}
.nav-tabs .nav-link:active, 
.nav-tabs .nav-link:focus, 
.nav-tabs .nav-link:hover {
	background-color: transparent;
}
.modal {
	display: none;
}
.modal .modal-title,
.modal .modal-body {
	white-space: initial;
}
.expansion-panel-toggler i {
	font-size: 24px;
}
.dropdown-menu {
	padding: 0;
}
.dropdown-menu:before {
	box-shadow: none;
	border: 1px solid var(--light-gray-color);
	border-radius: 10px;
}
/********** smartForm **********/
.smartForm {	
    margin: 0 auto;
    padding: 20px;
}
.smartForm .form-group small {
	color: var(--dark-gray-color);
	white-space: normal;
}
.smartForm .form-group .iti {
	display: block;
}
.smartForm .form-group label {
    color: #565656;
    font-size: 14px;
    font-weight: 600;
    padding: 5px;
    white-space: normal;
}
.smartForm .form-group input[type=color] {
	min-height: 37px;
    margin: 5px;
    padding: 4px;
}
.smartForm textarea.form-control,
.smartForm .form-control {
	border: 1px solid var(--light-gray-color);   
    border-radius: 10px;
	padding: 10px;
	min-height: 50px;
}
.smartForm textarea.form-control:focus,
.smartForm textarea.form-control:hover,
.smartForm textarea.form-control:active,
.smartForm .form-control:focus,
.smartForm .form-control:hover,
.smartForm .form-control:active {
	border-color: var(--first-color);
    box-shadow: none;
    outline: 0;
}
.smartForm .checkboxDiv {
    margin: 15px 0;
}
.smartForm :checked+.checkbox__checkmark:before {
	background-color: var(--first-color);
	border-color: var(--first-color);
}
.smartForm .text-input--material:focus {
	background-image: linear-gradient(to top,transparent 1px,var(--first-color) 1px);
}
.smartForm .text-input--material__label--active {
	color: var(--first-color);
}
.smartForm .link {
	font-size: 12px;
	margin: 5px 0;
}
.smartForm .link  a{
	color: #333;
    text-decoration: none;
}
.smartForm #fbLoginBtn {
    background-color: #3b5998;
    border-color: #3b5998;
    color: #FFF;
}
.smartForm #btnLoginSubmit,
.smartForm #btnSubmit {
    background-color: var(--first-color);
    border-color: var(--first-color);
    color: #FFF;
}
.smartForm label.error {
    width: auto !important;
    color: red;
    padding-top: .5em;
    vertical-align: top;
    font-weight:normal;
    text-shadow: none;
    font-size: 12px;
    white-space: normal;
    display: none;
}
.smartForm .expansion-panel-toggler {
	color: var(--black-color);
}
.smartForm .custom-radio label,
.smartForm .custom-checkbox label,
.smartForm .custom-switch label {
	font-weight: normal;
	cursor: pointer;
	white-space: normal;
	padding: 0 5px;
}	
.smartForm .custom-checkbox .custom-control-label:before,
.smartForm .custom-checkbox .custom-control-label:after,
.smartForm .custom-radio .custom-control-label:before,
.smartForm .custom-radio .custom-control-label:after {
	font-family: 'Material Symbols Outlined';
	position: absolute;
    left: -30px;
}
.smartForm .custom-switch .custom-control-track,
.smartForm .custom-switch .custom-control-label:before {
	background-color: var(--light-gray-color);
	height: 24px;
	width: 45px;
}
.smartForm .custom-switch .custom-control-label:after {
	width: 15px;
    height: 15px;
    margin-top: -4px;
    margin-left: -24px;
}
.smartForm .custom-radio .custom-control-input:checked~.custom-control-label:after,
.smartForm .custom-checkbox .custom-control-input:checked~.custom-control-label:after {
	color: var(--secondary-color);
	font-variation-settings: 'FILL' 1;
}
.smartForm .custom-checkbox .custom-control-input:checked~.custom-control-label:before,
.smartForm .custom-switch .custom-control-input:checked~.custom-control-label:before,
.smartForm .custom-switch .custom-control-input:checked~.custom-control-label:after {
	background-color: var(--secondary-color);
	font-variation-settings: 'FILL' 1;
}
.smartForm .custom-switch .custom-control-input:checked~.custom-control-track {
    background-color: var(--secondary-color);
    opacity: 0.7;
}
.smartForm .smartSlider {
	margin: 40px auto 20px auto;
	max-width: 70%;
}
.smartForm .smartSlider .ui-slider {
	border-radius: 20px;
    height: 8px;
}
.smartForm .smartSlider .ui-slider-range-min {
	background-color: var(--secondary-color);
}
.smartForm .smartSlider .ui-slider-handle {
	background-color: var(--secondary-color);
	border-color: var(--text-secondary-color);
    border-radius: 20px;
	top: -6px;	
}
.smartForm .smartSlider .sliderLabel {
    position: absolute;
    top: -30px;
    left: -50px;
    width: 100px;
    padding: 2px;
    text-align: center;
    background-color: var(--secondary-color);
    color: var(--text-secondary-color);
    font-weight: bold;
    border-radius: 10px;
}
.smartForm #conditionsError {
	display: none;
	margin: 0;
	font-size: 12px;
	color: red;
}
.smartLink {
	color: var(--first-color);
}
.smartBtn {	
    min-width: auto;
	width: auto;
	font-weight: bold;
	margin: 10px 0;
	font-size: 16px;
	line-height: 20px;
    text-transform: initial;
	text-align: center;
	border: none;
	border-radius: 50px;
	opacity: 0.9;
    background-color: transparent;
    background-image: linear-gradient(45deg, #30f6a8 0%, #0b84e0 100%);
	color: var(--white-color);
    border-radius: 50px;
    padding: 15px 50px;
}
.smartBtn:focus,
.smartBtn:hover,
.smartBtn:active {
    background-color: transparent;
    background-image: linear-gradient(45deg, #30f6a8 0%, #0b84e0 100%);
	color: var(--white-color);
}
.smartBtn:disabled {	
	background-color: var(--secondary-color);
	color: var(--text-secondary-color);
    opacity: 0.5;
}
.smartForm .btn-group {
	box-shadow: none;
	margin: 10px auto;
	width: auto;
}
.smartForm .btn-group .smartBtn {
	background-color: var(--secondary-color);
    color: var(--white-color);
	border: 1px solid var(--light-gray-color);
	margin: 0;
	opacity: 0.3;
	font-size: 12px;
	white-space: nowrap;
}
.smartForm .btn-group .smartBtn.active,
.smartForm .btn-group .smartBtn.active {
	opacity: 1;	
}

.smartForm .smartInputGroup.smartInputGroupPrepend > .form-control {
	border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.smartForm .smartInputGroup.smartInputGroupAppend > .form-control {
	border-right: none;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.smartForm .smartInputGroup .input-group-prepend,
.smartForm .smartInputGroup .input-group-append {	
    padding: 0;
    margin: 0;
	font-size: 16px;
    line-height: 14px;
}
.smartForm .smartInputGroup .input-group-prepend > span,
.smartForm .smartInputGroup .input-group-append > span {
	height: 100%;
	padding: 8px 10px;
    font-size: 16px;
    line-height: 32px;
    background-color: var(--light-gray-color);
    color: var(--black-color);
    border: 1px solid #999;
}
.smartForm .smartInputGroup .input-group-prepend > .btn,
.smartForm .smartInputGroup .input-group-append > .btn {
    height: 100%;
    padding: 15px;
    background-color: var(--secondary-color);
    color: var(--text-secondary-color);
    border: 1px solid #999;
}
.smartForm .smartInputGroup .input-group-prepend > select,
.smartForm .smartInputGroup .input-group-append > select {
	height: 100%;
	padding: 6px 25px 6px 10px;
    line-height: 32px;
    background-color: var(--light-gray-color);
    color: var(--black-color);
    border: 1px solid #999;
}
.smartForm .smartInputGroup .input-group-prepend > span,
.smartForm .smartInputGroup .input-group-prepend > .btn,
.smartForm .smartInputGroup .input-group-prepend > select {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.smartForm .smartInputGroup .input-group-append > span,
.smartForm .smartInputGroup .input-group-append > .btn,
.smartForm .smartInputGroup .input-group-append > select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
	border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;	
}
.smartForm .smartInputGroup .input-group-prepend > .btn:disabled,
.smartForm .smartInputGroup .input-group-append > .btn:disabled {
    background-color: var(--light-gray-color);
    color: var(--black-color);
}




.smartForm .smartColor {
	background-color: transparent;
}
.smartForm .smartColor input.form-control {
    border-top-right-radius: 0;
	border-bottom-right-radius: 0;
    font-size: 12px;
    height: 46px;
}
.smartForm .smartColor .input-group-append {
	margin-left: 0;
}
.smartForm .smartColor .input-group-append .input-group-text {
	margin-left: 0;
	margin-right: 0;
}
.smartForm .smartColor .input-group-append .input-group-text:before {
	display: none;
}
.smartForm .smartColor {	
	touch-action: none;	
}
.smartForm .smartColor .input-group-append i {
	height: 46px;
    width: 46px;
	border: 1px solid #999;
	border-left: none;
	border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
/********** smartCard **********/
.smartCard {
    margin: 20px 10px;
	padding: 10px;
    background-color: #FFF;
	border-radius: 10px;
	-moz-box-shadow: 1px 2px 1px 1px var(--light-gray-color);
	-webkit-box-shadow: 1px 2px 1px 1px var(--light-gray-color);
	box-shadow: 1px 2px 1px 1px var(--light-gray-color);
}
/********** smartBadge **********/
.smartBadge {
	border-radius: 6px;
	margin: 2px;
	font-size: 11px;
}
.smartBadge.badge-primary {
	background-color: white;
	border: 1px solid var(--primary);
	color: var(--primary);
}
.smartBadge.badge-secondary {
	background-color: white;
	border: 1px solid var(--secondary);
	color: var(--secondary);
}
.smartBadge.badge-success {
	background-color: white;
	border: 1px solid var(--success);
	color: var(--success);
}
.smartBadge.badge-info {
	background-color: white;
	border: 1px solid var(--info);
	color: var(--info);
}
.smartBadge.badge-warning {
	background-color: white;
	border: 1px solid var(--warning);
	color: var(--warning);
}
.smartBadge.badge-danger {
	background-color: white;
	border: 1px solid var(--danger);
	color: var(--danger);
}

/********** pickerDate **********/
.picker {	
	z-index: 100000;
}
.picker .picker-nav-disabled {
	opacity: 0.1;
}
.picker .picker-date-display {
    background-color: var(--first-color);	
	color: var(--text-first-color);
}
.picker .picker-select-month,
.picker .picker-select-year {
	min-width: 65px;
	height: 30px;
	margin-top: 8px;
	background-color: var(--secondary-color);	
	color: var(--text-secondary-color);
	border-radius: 20px;
	opacity: 1;
	font-weight: bold;
    text-transform: uppercase;
}
.picker .picker-date-display-bottom {
	font-size: 25px;
}
.picker .picker-day.picker-day-today {
	color: var(--secondary-color);
}
.picker .picker-day.picker-day-selected {
    background-color: var(--secondary-color);	
	color: var(--text-secondary-color);
}
.picker .picker-day.unavailable {
	text-decoration: line-through;
}
.picker .btn-outline-primary {
	background-color: var(--secondary-color);
	color: var(--text-secondary-color);
	border-radius: 20px;
}
.picker .material-icons {
	font-family: 'Material Symbols Outlined';
}
/********** mdtimepicker **********/
.mdtimepicker {
	z-index: 100000;
}
.mdtimepicker .mdtp__wrapper {
    top: 25%;
    bottom: inherit;
	box-shadow: none;
}
.mdtimepicker .mdtp__wrapper[data-theme='blue'] .mdtp__time_holder {
	background-color: var(--first-color);
}
.mdtimepicker .mdtp__wrapper[data-theme='blue'] .mdtp__button {
	background-color: var(--secondary-color);
    color: var(--text-secondary-color);
    border-radius: 20px;
	margin: 0 5px;
}
.mdtimepicker .mdtp__wrapper[data-theme='blue'] .mdtp__digit.active span, 
.mdtimepicker .mdtp__wrapper[data-theme='blue'] .mdtp__clock .mdtp__digit:not(.digit--disabled) span:hover,
.mdtimepicker .mdtp__wrapper[data-theme='blue'] .mdtp__digit.active:before,
.mdtimepicker .mdtp__wrapper[data-theme='blue'] .mdtp__clock .mdtp__clock_dot {
	background-color: var(--first-color) !important;
}
/********** dropzone **********/
.dropzone {
    min-height: 150px;
    padding: 5px;
}
.dropzone.dz-clickable {
    border: 1px solid var(--light-gray-color);
    border-radius: 10px;
}
.dropzone.dz-started .dz-default.dz-message {
	display: none;
}
.dropzone .dz-default.dz-message {
    background-image: none;
	position: relative;
    text-align: center;
    margin-top: 10px;
}
.dropzone .dz-default.dz-message .dz-button {
	color: var(--dark-gray-color);
}
.dropzone .dz-default.dz-message span {
    font-size: 14px !important;
    padding: 50px;
}
.dropzone .dz-preview .dz-details {
	padding: 10px;
}
.dropzone .dz-preview .dz-image img {
    border-radius: 20px;
    overflow: hidden;
    width: 120px;
    height: 120px;
    position: relative;
    display: block;
    z-index: 10;
	cursor: move;
}
.dropzone .dz-preview .dz-size,
.dropzone .dz-preview .dz-progress {
	display: none;	
}
.dropzone .dz-preview .dz-remove {
	text-transform: uppercase;
	color: var(--text-secondary-color);
}
/********** emptyContent *****************/
.emptyContent {
	margin: 50px auto;	
	text-align: center;
	color: #999;
}
.emptyContent .img img {
	width: 100px;
	opacity: .5;
}
.emptyContent .text {
	margin-top: 20px;	
}
.emptyContent .cta {
	margin-top: 20px;
	width: auto;
}
/********** JqueryUI *****************/
.ui-widget.ui-widget-content {
	max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}
.ui-widget.ui-widget-content .ui-menu-item {
	color: var(--text-secondary-color);
	min-height: 50px;
}
.ui-widget.ui-widget-content .ui-state-active {
	background-color: var(--secondary-color);
	color: var(--text-secondary-color);
	border: none;
	min-height: 50px;
}

.ui-widget.ui-widget-content.ui-datepicker-dialog {
    z-index: 100000000000000 !important;
    display: block !important;
    width: 100%;
    height: 100%;
    max-height: 100%;
    background-color: var(--modal-background-color);
}
.ui-widget.ui-widget-content.ui-datepicker-dialog .ui-datepicker-header {
    width: 300px;
    height: auto;
	padding: 20px;
    margin: 0 auto;
	margin-top: 100px;
	background-color: #FFF;
    border: none;
}
.ui-widget.ui-widget-content.ui-datepicker-dialog .ui-datepicker-calendar {
    max-width: 300px;
    margin: 0 auto;
    background-color: #FFF;
}
.ui-widget.ui-widget-content.ui-datepicker-dialog .ui-datepicker-buttonpane {
    min-width: 300px;
    max-width: 300px;
	min-height: auto;
	height: 55px;
	padding: 10px 0;
    margin: 0 auto;
	border: none;
}

.ui-widget.ui-widget-content .ui-datepicker-prev,
.ui-widget.ui-widget-content .ui-datepicker-next {
	top: 20px;
}
.ui-widget.ui-widget-content .ui-datepicker-current,
.ui-widget.ui-widget-content .ui-datepicker-close {
	border: none;
	background-color: transparent;
	text-transform: uppercase;
}
.ui-widget.ui-widget-content .ui-datepicker-month,
.ui-widget.ui-widget-content .ui-datepicker-year {
	border: none;
    background-color: transparent;
    max-width: 60px;
}
.ui-widget.ui-widget-content .ui-datepicker-calendar .ui-state-default {
	border: none;
    background-color: transparent;
	width: 30px;
    height: 30px;
    padding: 0;
	font-size: 14px;
    line-height: 30px;
    text-align: center;
}
.ui-widget.ui-widget-content .ui-datepicker-calendar .ui-state-active {
    min-height: 30px;
    background-color: var(--secondary-color);
    color: var(--text-secondary-color);
    border-radius: 50%;
}
/********** GoogleMaps Autocomplete *****************/
.pac-container {
	z-index: 10002;
}
/********** Animations **********/
@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
@keyframes smallPulse {
	0% {
		transform: scale(0.8);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
	}
	100% {
		transform: scale(0.8);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
@keyframes wiggle {
	0%, 7% {
		transform: rotateZ(0);
	}
	15% {
		transform: rotateZ(-15deg);
	}
	20% {
		transform: rotateZ(10deg);
	}
	25% {
		transform: rotateZ(-10deg);
	}
	30% {
		transform: rotateZ(6deg);
	}
	35% {
		transform: rotateZ(-4deg);
	}
	40%, 100% {
		transform: rotateZ(0);
	}
}

@keyframes bounce {

	20%,
	53%,
	80%,
	from,
	to {
		animation-timing-function: cubic-bezier(.215, .61, .355, 1);
		transform: translate3d(0, 0, 0)
	}

	40%,
	43% {
		animation-timing-function: cubic-bezier(.755, .050, .855, .060);
		transform: translate3d(0, -30px, 0)
	}

	70% {
		animation-timing-function: cubic-bezier(.755, .050, .855, .060);
		transform: translate3d(0, -15px, 0)
	}

	90% {
		transform: translate3d(0, -4px, 0)
	}
}

.bounce {
	animation-name: bounce;
	transform-origin: center bottom
}

@keyframes flash {

	50%,
	from,
	to {
		opacity: 1
	}

	25%,
	75% {
		opacity: 0
	}
}

.flash {
	animation-name: flash
}

@keyframes pulse {

	from,
	to {
		transform: scale3d(1, 1, 1)
	}

	50% {
		transform: scale3d(1.05, 1.05, 1.05)
	}
}

.pulse {
	animation-name: pulse
}

@keyframes rubberBand {

	from,
	to {
		transform: scale3d(1, 1, 1)
	}

	30% {
		transform: scale3d(1.25, .75, 1)
	}

	40% {
		transform: scale3d(.75, 1.25, 1)
	}

	50% {
		transform: scale3d(1.15, .85, 1)
	}

	65% {
		transform: scale3d(.95, 1.05, 1)
	}

	75% {
		transform: scale3d(1.05, .95, 1)
	}
}

.rubberBand {
	animation-name: rubberBand
}

@keyframes shake {

	from,
	to {
		transform: translate3d(0, 0, 0)
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		transform: translate3d(-10px, 0, 0)
	}

	20%,
	40%,
	60%,
	80% {
		transform: translate3d(10px, 0, 0)
	}
}

.shake {
	animation-name: shake
}

@keyframes headShake {
	0% {
		transform: translateX(0)
	}

	6.5% {
		transform: translateX(-6px) rotateY(-9deg)
	}

	18.5% {
		transform: translateX(5px) rotateY(7deg)
	}

	31.5% {
		transform: translateX(-3px) rotateY(-5deg)
	}

	43.5% {
		transform: translateX(2px) rotateY(3deg)
	}

	50% {
		transform: translateX(0)
	}
}

.headShake {
	animation-timing-function: ease-in-out;
	animation-name: headShake
}

@keyframes swing {
	20% {
		transform: rotate3d(0, 0, 1, 15deg)
	}

	40% {
		transform: rotate3d(0, 0, 1, -10deg)
	}

	60% {
		transform: rotate3d(0, 0, 1, 5deg)
	}

	80% {
		transform: rotate3d(0, 0, 1, -5deg)
	}

	to {
		transform: rotate3d(0, 0, 1, 0deg)
	}
}

.swing {
	transform-origin: top center;
	animation-name: swing
}

@keyframes tada {

	from,
	to {
		transform: scale3d(1, 1, 1)
	}

	10%,
	20% {
		transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
	}

	30%,
	50%,
	70%,
	90% {
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
	}

	40%,
	60%,
	80% {
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
	}
}

.tada {
	animation-name: tada
}

@keyframes wobble {

	from,
	to {
		transform: none
	}

	15% {
		transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
	}

	30% {
		transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
	}

	45% {
		transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
	}

	60% {
		transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
	}

	75% {
		transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
	}
}

.wobble {
	animation-name: wobble
}

@keyframes jello {

	11.1%,
	from,
	to {
		transform: none
	}

	22.2% {
		transform: skewX(-12.5deg) skewY(-12.5deg)
	}

	33.3% {
		transform: skewX(6.25deg) skewY(6.25deg)
	}

	44.4% {
		transform: skewX(-3.125deg) skewY(-3.125deg)
	}

	55.5% {
		transform: skewX(1.5625deg) skewY(1.5625deg)
	}

	66.6% {
		transform: skewX(-.78125deg) skewY(-.78125deg)
	}

	77.7% {
		transform: skewX(.390625deg) skewY(.390625deg)
	}

	88.8% {
		transform: skewX(-.1953125deg) skewY(-.1953125deg)
	}
}

.jello {
	animation-name: jello;
	transform-origin: center
}

@keyframes bounceIn {

	20%,
	40%,
	60%,
	80%,
	from,
	to {
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}

	0% {
		opacity: 0;
		transform: scale3d(.3, .3, .3)
	}

	20% {
		transform: scale3d(1.1, 1.1, 1.1)
	}

	40% {
		transform: scale3d(.9, .9, .9)
	}

	60% {
		opacity: 1;
		transform: scale3d(1.03, 1.03, 1.03)
	}

	80% {
		transform: scale3d(.97, .97, .97)
	}

	to {
		opacity: 1;
		transform: scale3d(1, 1, 1)
	}
}

.bounceIn {
	animation-name: bounceIn
}

@keyframes bounceInDown {

	60%,
	75%,
	90%,
	from,
	to {
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}

	0% {
		opacity: 0;
		transform: translate3d(0, -3000px, 0)
	}

	60% {
		opacity: 1;
		transform: translate3d(0, 25px, 0)
	}

	75% {
		transform: translate3d(0, -10px, 0)
	}

	90% {
		transform: translate3d(0, 5px, 0)
	}

	to {
		transform: none
	}
}

.bounceInDown {
	animation-name: bounceInDown
}

@keyframes bounceInLeft {

	60%,
	75%,
	90%,
	from,
	to {
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}

	0% {
		opacity: 0;
		transform: translate3d(-3000px, 0, 0)
	}

	60% {
		opacity: 1;
		transform: translate3d(25px, 0, 0)
	}

	75% {
		transform: translate3d(-10px, 0, 0)
	}

	90% {
		transform: translate3d(5px, 0, 0)
	}

	to {
		transform: none
	}
}

.bounceInLeft {
	animation-name: bounceInLeft
}

@keyframes bounceInRight {

	60%,
	75%,
	90%,
	from,
	to {
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}

	from {
		opacity: 0;
		transform: translate3d(3000px, 0, 0)
	}

	60% {
		opacity: 1;
		transform: translate3d(-25px, 0, 0)
	}

	75% {
		transform: translate3d(10px, 0, 0)
	}

	90% {
		transform: translate3d(-5px, 0, 0)
	}

	to {
		transform: none
	}
}

.bounceInRight {
	animation-name: bounceInRight
}

@keyframes bounceInUp {

	60%,
	75%,
	90%,
	from,
	to {
		animation-timing-function: cubic-bezier(.215, .61, .355, 1)
	}

	from {
		opacity: 0;
		transform: translate3d(0, 3000px, 0)
	}

	60% {
		opacity: 1;
		transform: translate3d(0, -20px, 0)
	}

	75% {
		transform: translate3d(0, 10px, 0)
	}

	90% {
		transform: translate3d(0, -5px, 0)
	}

	to {
		transform: translate3d(0, 0, 0)
	}
}

.bounceInUp {
	animation-name: bounceInUp
}

@keyframes fadeIn {
	from {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -100%, 0)
	}

	to {
		opacity: 1;
		transform: none
	}
}

.fadeInDown {
	animation-name: fadeInDown
}

@keyframes fadeInLeft {
	from {
		opacity: 0;
		transform: translate3d(-100%, 0, 0)
	}

	to {
		opacity: 1;
		transform: none
	}
}

.fadeInLeft {
	animation-name: fadeInLeft
}

@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translate3d(100%, 0, 0)
	}

	to {
		opacity: 1;
		transform: none
	}
}

.fadeInRight {
	animation-name: fadeInRight
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 100%, 0)
	}

	to {
		opacity: 1;
		transform: none
	}
}

.fadeInUp {
	animation-name: fadeInUp
}

@keyframes lightSpeedIn {
	from {
		transform: translate3d(100%, 0, 0) skewX(-30deg);
		opacity: 0
	}

	60% {
		transform: skewX(20deg);
		opacity: 1
	}

	80% {
		transform: skewX(-5deg);
		opacity: 1
	}

	to {
		transform: none;
		opacity: 1
	}
}

.lightSpeedIn {
	animation-name: lightSpeedIn;
	animation-timing-function: ease-out
}

@keyframes rotateIn {
	from {
		transform-origin: center;
		transform: rotate3d(0, 0, 1, -200deg);
		opacity: 0
	}

	to {
		transform-origin: center;
		transform: none;
		opacity: 1
	}
}

.rotateIn {
	animation-name: rotateIn
}

@keyframes rotateInDownLeft {
	from {
		transform-origin: left bottom;
		transform: rotate3d(0, 0, 1, -45deg);
		opacity: 0
	}

	to {
		transform-origin: left bottom;
		transform: none;
		opacity: 1
	}
}

.rotateInDownLeft {
	animation-name: rotateInDownLeft
}

@keyframes rotateInDownRight {
	from {
		transform-origin: right bottom;
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0
	}

	to {
		transform-origin: right bottom;
		transform: none;
		opacity: 1
	}
}

.rotateInDownRight {
	animation-name: rotateInDownRight
}

@keyframes rotateInUpLeft {
	from {
		transform-origin: left bottom;
		transform: rotate3d(0, 0, 1, 45deg);
		opacity: 0
	}

	to {
		transform-origin: left bottom;
		transform: none;
		opacity: 1
	}
}

.rotateInUpLeft {
	animation-name: rotateInUpLeft
}

@keyframes rotateInUpRight {
	from {
		transform-origin: right bottom;
		transform: rotate3d(0, 0, 1, -90deg);
		opacity: 0
	}

	to {
		transform-origin: right bottom;
		transform: none;
		opacity: 1
	}
}

.rotateInUpRight {
	animation-name: rotateInUpRight
}

@keyframes rollIn {
	from {
		opacity: 0;
		transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg)
	}

	to {
		opacity: 1;
		transform: none
	}
}

.rollIn {
	animation-name: rollIn
}

@keyframes zoomIn {
	from {
		opacity: 0;
		transform: scale3d(.3, .3, .3)
	}

	50% {
		opacity: 1
	}
}

.zoomIn {
	animation-name: zoomIn
}

@keyframes zoomInDown {
	from {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
		animation-timing-function: cubic-bezier(.55, .055, .675, .19)
	}

	60% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
		animation-timing-function: cubic-bezier(.175, .885, .32, 1)
	}
}

.zoomInDown {
	animation-name: zoomInDown
}

@keyframes zoomInLeft {
	from {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(-1000px, 0, 0);
		animation-timing-function: cubic-bezier(.55, .055, .675, .19)
	}

	60% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
		animation-timing-function: cubic-bezier(.175, .885, .32, 1)
	}
}

.zoomInLeft {
	animation-name: zoomInLeft
}

@keyframes zoomInRight {
	from {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(1000px, 0, 0);
		animation-timing-function: cubic-bezier(.55, .055, .675, .19)
	}

	60% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
		animation-timing-function: cubic-bezier(.175, .885, .32, 1)
	}
}

.zoomInRight {
	animation-name: zoomInRight
}

@keyframes zoomInUp {
	from {
		opacity: 0;
		transform: scale3d(.1, .1, .1) translate3d(0, 1000px, 0);
		animation-timing-function: cubic-bezier(.55, .055, .675, .19)
	}

	60% {
		opacity: 1;
		transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
		animation-timing-function: cubic-bezier(.175, .885, .32, 1)
	}
}

.zoomInUp {
	animation-name: zoomInUp
}

@keyframes slideInDown {
	from {
		transform: translate3d(0, -100%, 0);
		visibility: visible
	}

	to {
		transform: translate3d(0, 0, 0)
	}
}

.slideInDown {
	animation-name: slideInDown
}

@keyframes slideInLeft {
	from {
		transform: translate3d(-100%, 0, 0);
		visibility: visible
	}

	to {
		transform: translate3d(0, 0, 0)
	}
}

.slideInLeft {
	animation-name: slideInLeft
}

@keyframes slideInRight {
	from {
		transform: translate3d(100%, 0, 0);
		visibility: visible
	}

	to {
		transform: translate3d(0, 0, 0)
	}
}

.slideInRight {
	animation-name: slideInRight
}

@keyframes slideInUp {
	from {
		transform: translate3d(0, 100%, 0);
		visibility: visible
	}

	to {
		transform: translate3d(0, 0, 0)
	}
}

.slideInUp {
	animation-name: slideInUp
}

.elementor-animation-grow {
	transition-duration: .3s;
	transition-property: transform
}

.elementor-animation-grow:active,
.elementor-animation-grow:focus,
.elementor-animation-grow:hover {
	transform: scale(1.1)
}

.elementor-animation-shrink {
	transition-duration: .3s;
	transition-property: transform
}

.elementor-animation-shrink:active,
.elementor-animation-shrink:focus,
.elementor-animation-shrink:hover {
	transform: scale(0.9)
}

@keyframes elementor-animation-pulse {
	25% {
		transform: scale(1.1)
	}

	75% {
		transform: scale(0.9)
	}
}

.elementor-animation-pulse:active,
.elementor-animation-pulse:focus,
.elementor-animation-pulse:hover {
	animation-name: elementor-animation-pulse;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-iteration-count: infinite
}

@keyframes elementor-animation-pulse-grow {
	to {
		transform: scale(1.1)
	}
}

.elementor-animation-pulse-grow:active,
.elementor-animation-pulse-grow:focus,
.elementor-animation-pulse-grow:hover {
	animation-name: elementor-animation-pulse-grow;
	animation-duration: .3s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-direction: alternate
}

@keyframes elementor-animation-pulse-shrink {
	to {
		transform: scale(0.9)
	}
}

.elementor-animation-pulse-shrink:active,
.elementor-animation-pulse-shrink:focus,
.elementor-animation-pulse-shrink:hover {
	animation-name: elementor-animation-pulse-shrink;
	animation-duration: .3s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-direction: alternate
}

@keyframes elementor-animation-push {
	50% {
		transform: scale(0.8)
	}

	100% {
		transform: scale(1)
	}
}

.elementor-animation-push:active,
.elementor-animation-push:focus,
.elementor-animation-push:hover {
	animation-name: elementor-animation-push;
	animation-duration: .3s;
	animation-timing-function: linear;
	animation-iteration-count: 1
}

@keyframes elementor-animation-pop {
	50% {
		transform: scale(1.2)
	}
}

.elementor-animation-pop:active,
.elementor-animation-pop:focus,
.elementor-animation-pop:hover {
	animation-name: elementor-animation-pop;
	animation-duration: .3s;
	animation-timing-function: linear;
	animation-iteration-count: 1
}

.elementor-animation-bounce-in {
	transition-duration: .5s
}

.elementor-animation-bounce-in:active,
.elementor-animation-bounce-in:focus,
.elementor-animation-bounce-in:hover {
	transform: scale(1.2);
	transition-timing-function: cubic-bezier(0.47, 2.02, .31, -.36)
}

.elementor-animation-bounce-out {
	transition-duration: .5s
}

.elementor-animation-bounce-out:active,
.elementor-animation-bounce-out:focus,
.elementor-animation-bounce-out:hover {
	transform: scale(0.8);
	transition-timing-function: cubic-bezier(0.47, 2.02, .31, -.36)
}

.elementor-animation-rotate {
	transition-duration: .3s;
	transition-property: transform
}

.elementor-animation-rotate:active,
.elementor-animation-rotate:focus,
.elementor-animation-rotate:hover {
	transform: rotate(4deg)
}

.elementor-animation-grow-rotate {
	transition-duration: .3s;
	transition-property: transform
}

.elementor-animation-grow-rotate:active,
.elementor-animation-grow-rotate:focus,
.elementor-animation-grow-rotate:hover {
	transform: scale(1.1) rotate(4deg)
}

.elementor-animation-float {
	transition-duration: .3s;
	transition-property: transform;
	transition-timing-function: ease-out
}

.elementor-animation-float:active,
.elementor-animation-float:focus,
.elementor-animation-float:hover {
	transform: translateY(-8px)
}

.elementor-animation-sink {
	transition-duration: .3s;
	transition-property: transform;
	transition-timing-function: ease-out
}

.elementor-animation-sink:active,
.elementor-animation-sink:focus,
.elementor-animation-sink:hover {
	transform: translateY(8px)
}

@keyframes elementor-animation-bob {
	0% {
		transform: translateY(-8px)
	}

	50% {
		transform: translateY(-4px)
	}

	100% {
		transform: translateY(-8px)
	}
}

@keyframes elementor-animation-bob-float {
	100% {
		transform: translateY(-8px)
	}
}

.elementor-animation-bob:active,
.elementor-animation-bob:focus,
.elementor-animation-bob:hover {
	animation-name: elementor-animation-bob-float, elementor-animation-bob;
	animation-duration: .3s, 1.5s;
	animation-delay: 0s, .3s;
	animation-timing-function: ease-out, ease-in-out;
	animation-iteration-count: 1, infinite;
	animation-fill-mode: forwards;
	animation-direction: normal, alternate
}

@keyframes elementor-animation-hang {
	0% {
		transform: translateY(8px)
	}

	50% {
		transform: translateY(4px)
	}

	100% {
		transform: translateY(8px)
	}
}

@keyframes elementor-animation-hang-sink {
	100% {
		transform: translateY(8px)
	}
}

.elementor-animation-hang:active,
.elementor-animation-hang:focus,
.elementor-animation-hang:hover {
	animation-name: elementor-animation-hang-sink, elementor-animation-hang;
	animation-duration: .3s, 1.5s;
	animation-delay: 0s, .3s;
	animation-timing-function: ease-out, ease-in-out;
	animation-iteration-count: 1, infinite;
	animation-fill-mode: forwards;
	animation-direction: normal, alternate
}

.elementor-animation-skew {
	transition-duration: .3s;
	transition-property: transform
}

.elementor-animation-skew:active,
.elementor-animation-skew:focus,
.elementor-animation-skew:hover {
	transform: skew(-10deg)
}

.elementor-animation-skew-forward {
	transition-duration: .3s;
	transition-property: transform;
	transform-origin: 0 100%
}

.elementor-animation-skew-forward:active,
.elementor-animation-skew-forward:focus,
.elementor-animation-skew-forward:hover {
	transform: skew(-10deg)
}

.elementor-animation-skew-backward {
	transition-duration: .3s;
	transition-property: transform;
	transform-origin: 0 100%
}

.elementor-animation-skew-backward:active,
.elementor-animation-skew-backward:focus,
.elementor-animation-skew-backward:hover {
	transform: skew(10deg)
}

@keyframes elementor-animation-wobble-vertical {
	16.65% {
		transform: translateY(8px)
	}

	33.3% {
		transform: translateY(-6px)
	}

	49.95% {
		transform: translateY(4px)
	}

	66.6% {
		transform: translateY(-2px)
	}

	83.25% {
		transform: translateY(1px)
	}

	100% {
		transform: translateY(0)
	}
}

.elementor-animation-wobble-vertical:active,
.elementor-animation-wobble-vertical:focus,
.elementor-animation-wobble-vertical:hover {
	animation-name: elementor-animation-wobble-vertical;
	animation-duration: 1s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1
}

@keyframes elementor-animation-wobble-horizontal {
	16.65% {
		transform: translateX(8px)
	}

	33.3% {
		transform: translateX(-6px)
	}

	49.95% {
		transform: translateX(4px)
	}

	66.6% {
		transform: translateX(-2px)
	}

	83.25% {
		transform: translateX(1px)
	}

	100% {
		transform: translateX(0)
	}
}

.elementor-animation-wobble-horizontal:active,
.elementor-animation-wobble-horizontal:focus,
.elementor-animation-wobble-horizontal:hover {
	animation-name: elementor-animation-wobble-horizontal;
	animation-duration: 1s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1
}

@keyframes elementor-animation-wobble-to-bottom-right {
	16.65% {
		transform: translate(8px, 8px)
	}

	33.3% {
		transform: translate(-6px, -6px)
	}

	49.95% {
		transform: translate(4px, 4px)
	}

	66.6% {
		transform: translate(-2px, -2px)
	}

	83.25% {
		transform: translate(1px, 1px)
	}

	100% {
		transform: translate(0, 0)
	}
}

.elementor-animation-wobble-to-bottom-right:active,
.elementor-animation-wobble-to-bottom-right:focus,
.elementor-animation-wobble-to-bottom-right:hover {
	animation-name: elementor-animation-wobble-to-bottom-right;
	animation-duration: 1s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1
}

@keyframes elementor-animation-wobble-to-top-right {
	16.65% {
		transform: translate(8px, -8px)
	}

	33.3% {
		transform: translate(-6px, 6px)
	}

	49.95% {
		transform: translate(4px, -4px)
	}

	66.6% {
		transform: translate(-2px, 2px)
	}

	83.25% {
		transform: translate(1px, -1px)
	}

	100% {
		transform: translate(0, 0)
	}
}

.elementor-animation-wobble-to-top-right:active,
.elementor-animation-wobble-to-top-right:focus,
.elementor-animation-wobble-to-top-right:hover {
	animation-name: elementor-animation-wobble-to-top-right;
	animation-duration: 1s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1
}

@keyframes elementor-animation-wobble-top {
	16.65% {
		transform: skew(-12deg)
	}

	33.3% {
		transform: skew(10deg)
	}

	49.95% {
		transform: skew(-6deg)
	}

	66.6% {
		transform: skew(4deg)
	}

	83.25% {
		transform: skew(-2deg)
	}

	100% {
		transform: skew(0)
	}
}

.elementor-animation-wobble-top {
	transform-origin: 0 100%
}

.elementor-animation-wobble-top:active,
.elementor-animation-wobble-top:focus,
.elementor-animation-wobble-top:hover {
	animation-name: elementor-animation-wobble-top;
	animation-duration: 1s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1
}

@keyframes elementor-animation-wobble-bottom {
	16.65% {
		transform: skew(-12deg)
	}

	33.3% {
		transform: skew(10deg)
	}

	49.95% {
		transform: skew(-6deg)
	}

	66.6% {
		transform: skew(4deg)
	}

	83.25% {
		transform: skew(-2deg)
	}

	100% {
		transform: skew(0)
	}
}

.elementor-animation-wobble-bottom {
	transform-origin: 100% 0
}

.elementor-animation-wobble-bottom:active,
.elementor-animation-wobble-bottom:focus,
.elementor-animation-wobble-bottom:hover {
	animation-name: elementor-animation-wobble-bottom;
	animation-duration: 1s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1
}

@keyframes elementor-animation-wobble-skew {
	16.65% {
		transform: skew(-12deg)
	}

	33.3% {
		transform: skew(10deg)
	}

	49.95% {
		transform: skew(-6deg)
	}

	66.6% {
		transform: skew(4deg)
	}

	83.25% {
		transform: skew(-2deg)
	}

	100% {
		transform: skew(0)
	}
}

.elementor-animation-wobble-skew:active,
.elementor-animation-wobble-skew:focus,
.elementor-animation-wobble-skew:hover {
	animation-name: elementor-animation-wobble-skew;
	animation-duration: 1s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1
}

@keyframes elementor-animation-buzz {
	50% {
		transform: translateX(3px) rotate(2deg)
	}

	100% {
		transform: translateX(-3px) rotate(-2deg)
	}
}

.elementor-animation-buzz:active,
.elementor-animation-buzz:focus,
.elementor-animation-buzz:hover {
	animation-name: elementor-animation-buzz;
	animation-duration: .15s;
	animation-timing-function: linear;
	animation-iteration-count: infinite
}

@keyframes elementor-animation-buzz-out {
	10% {
		transform: translateX(3px) rotate(2deg)
	}

	20% {
		transform: translateX(-3px) rotate(-2deg)
	}

	30% {
		transform: translateX(3px) rotate(2deg)
	}

	40% {
		transform: translateX(-3px) rotate(-2deg)
	}

	50% {
		transform: translateX(2px) rotate(1deg)
	}

	60% {
		transform: translateX(-2px) rotate(-1deg)
	}

	70% {
		transform: translateX(2px) rotate(1deg)
	}

	80% {
		transform: translateX(-2px) rotate(-1deg)
	}

	90% {
		transform: translateX(1px) rotate(0)
	}

	100% {
		transform: translateX(-1px) rotate(0)
	}
}

.elementor-animation-buzz-out:active,
.elementor-animation-buzz-out:focus,
.elementor-animation-buzz-out:hover {
	animation-name: elementor-animation-buzz-out;
	animation-duration: .75s;
	animation-timing-function: linear;
	animation-iteration-count: 1
}


body {
	background-color: var(--navbar-color);
	font-family: Poppins;
	height: 100dvh;
}
.text-first-color {
	color: var(--first-color);
}
.text-secondary-color {
	color: var(--secondary-color);
}
a[data-toggle=popover] {
	color: var(--first-color);
}
/******************************************************************* HEADER */
#topToolbar #logo {
	width: auto;
    height: 40px;
	padding: 3px 0;
}
#topToolbar #languageBtn {
    min-width: auto;
    border-radius: 50%;
	font-size: 24px;
}
#topToolbar #languageBtn .flag {
    display: block;
    width: 18px;
    height: 14px;
}
#topToolbar .navbar-nav .nav-link {
    opacity: 1;
}
#topToolbar .navbar-nav .nav-link:focus,
#topToolbar .navbar-nav .nav-link:hover,
#topToolbar .navbar-nav .nav-link:active {
    opacity: 1;
    background-color: transparent;
}
@media screen and (max-width: 992px) {
	#topToolbar #logo img {
		height: 40px;
	}
}
/******************************************************************* FOOTER */
#footer {
	display: none;
    margin: 60px 20px;
    background-color: var(--navbar-color);
    color: var(--text-navbar-color);
}
#footer #logo {
	display: block;
	min-height: 90px;
}
#footer #logo img {
    width: 230px;
	height: auto;
}
#footer .title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid var(--white-color);
}
#footer .links {
    padding: 20px 0;
}
#footer .link {
    padding: 5px 0;
}
#footer .link a {
    color: var(--white-color);
}
#footer .socialLinks {
	padding: 20px 0;
}
#footer .socialLinks a {
    margin-left: 3px;
    margin-right: 3px;
}
#footer .socialLinks a img {
    width: 20px;
    height: 20px;
}
#footer #copyrightDiv{ 
    margin-top: 20px;
    padding: 20px;
	text-align: center;
}
@media screen and (max-width: 992px) {
    #footer {
        margin: 0;
        padding: 30px 20px 60px 20px;
        height: auto;
        text-align: center;
    }
	#footer #logo img {
		width: 180px;
	}
    #footer #claimDiv,
    #footer #copyrightDiv {
        text-align: center;
        margin: 0 auto;
    }
}
@media screen and (max-width: 572px) {
    #footer {
		display: none;		
	}
}
/******************************************************************* confirmOperationModal */
#confirmOperationModal {
    z-index: 10003;
}
#confirmOperationModal .modal-content {
	padding: 10px;
}
#confirmOperationModal .modal-body {
    padding: 10px;
	white-space: initial;
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 24px;
}
#confirmOperationModal .modal-footer {	
	border-top: none;
}
#confirmOperationModal .btn {
	border-radius: 20px;	
    padding: 10px;
	font-size: 14px;
}
#confirmOperationModal .btn#confirmOperationBtn {
	background-color: var(--danger);
    color: #FFF;
}
/******************************************************************* languageModal */
#languageModal .flag {
    display: block;
    width: 28px;
    height: 21px;
}
#languageModal .languageBtn {
    cursor: pointer;
    padding: 20px 0;
}
/******************************************************************* navdrawer */
#navdrawer .navdrawer-content {
	background-color: var(--navbar-color);
	color: var(--text-navbar-color);
	padding-top: env(safe-area-inset-top);
}
#navdrawer #logo img {
	width: 220px;
	padding: 30px 20px;
}
#navdrawer .list-group-item {
	display: flex;
	align-items: center;
	background-color: transparent;
	color: var(--white-color);
	cursor: pointer;
}
#navdrawer .list-group-item .icon {
	font-size: 24px;
	margin-right: 10px;
}
#navdrawer .list-group-item .text {
	line-height: 24px;
}